const config = {
  // WS_BASE_URL: "http://localhost:5100/api/", // LOCAL
  // DOMAIN_NAME: "http://localhost:3000", // LOCAL
  WS_BASE_URL: "https://sistema-de-eventos-backend.onrender.com/api/", // ONLINE
  // DOMAIN_NAME: "https://famur-events-frontend.herokuapp.com/", // ONLINE
  DOMAIN_NAME: "https://eventos.murialdo.com.br/", // ONLINE
  DEMO: true,
};

export default config;
